import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { reshapeEventData } from "../../helperFunctions"

import EventCards from "../EventCard"

const RenderEvents = ({
  upcomingEventsData,
  showPastEvents = false,
  darkText = false,
  hideShowMoreButton = false,
}) => {
  const { pastEvents } = useStaticQuery(graphql`
    query MyQuery {
      pastEvents: allSanityEvent {
        edges {
          node {
            id
            title
            isPastEvent
            eventDateTime
            bannerText
            slug {
              current
            }
            bannerHeroImage {
              asset {
                id
                url
                gatsbyImageData(
                  width: 200
                  aspectRatio: 1
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  `)

  const newEvents = pastEvents.edges.flatMap(event => [event.node])

  const eventData = newEvents.filter(event => {
    if (showPastEvents) {
      return event.isPastEvent === true
    } else {
      return event.isPastEvent === false
    }
  })

  // shape data to work with EventCards component
  const reshapedEventData = reshapeEventData(eventData)

  // Sort array by event date
  const sortedEventData = reshapedEventData.sort(
    (a, b) => b.dateObject - a.dateObject
  )

  return (
    <section className="">
      {sortedEventData.length > 0 ? (
        <EventCards
          eventData={sortedEventData}
          darkText={darkText}
          hideShowMoreButton={hideShowMoreButton}
        />
      ) : (
        <div
          className={`text-center text-3xl mt-10 ${
            darkText ? "text-primary" : "text-quaternary"
          }`}
        >
          Coming soon!
        </div>
      )}
    </section>
  )
}

export default RenderEvents
